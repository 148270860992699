import * as React from "react"
import { Link } from "gatsby"
import "./Header.css"
import Brkfst from "./Brkfst"

class Header extends React.Component {
    constructor(props) {
      super(props)
  
      this.state = {
        hasScrolled: false
      }
    }
  
    componentDidMount() {
      window.addEventListener('scroll', this.handleScroll)
  }
  
    handleScroll = (event) => {
      const scrollTop = window.pageYOffset
  
      if (scrollTop > 50) {
        this.setState({ hasScrolled: true })
      } else {
        this.setState({ hasScrolled: false })
      }
    }
  
    render() {
      return (
        <div className={this.state.hasScrolled ? 'Header HeaderScrolled' : 'Header'}>
          <div className="HeaderGroup">
            <Link className="HeaderGroupLeft" to="/">
              <Brkfst />
              <div className="Burrito"><span role="img" aria-label="burrito emoji">🌯</span></div>
            </Link>
            <div className="HeaderGroupRight">
              <Link to="/work/" activeStyle={{ color: "#3CC8D7" }}>work</Link>
              <Link to="/about/" activeStyle={{ color: "#3CC8D7" }}>about</Link>
              <Link to="/sheesh/" activeStyle={{ color: "#3CC8D7" }}>shee<span className="desktopStretch">e</span><span className="superStretch">eee</span>sh</Link>
            </div>
          </div>
        </div>
      )
    }
  }
  
  export default Header
  