import * as React from "react"
import styled from "styled-components"
import footerlinks from "../../footerlinks.json"
import { Helmet } from "react-helmet"

const FooterGroup = styled.div`
    max-width: 1344px;
    background: url('../images/tortilla.svg');
    background-size: cover;
    padding: 12px 0;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
`

const Copyright = styled.div`
    max-width: 180px;
    font-family: Alpino-Medium, Arial, Helvetica, sans-serif;
    margin-left: 48px;
`

const LinkGroup = styled.div`
    max-width: 50vw;
    display: grid;
    grid-template-columns: repeat(5, auto);
    align-items: center;
    grid-gap: 12px;
    justify-self: end;
    margin-right: 48px;

    a {
        content: "\f007";
        font-family: 'Font Awesome 5 Free', 'Font Awesome 5 Brands';
        font-size: 19.2px;
        color: rgba(0, 0, 0, .78);
        font-weight: 300;
    }

    a:hover {
        color: rgba(60, 200, 215, 1);
    }

    @media (max-width: 640px) {
        grid-template-columns: repeat(3, auto);
        grid-gap: 24px;

        & a:nth-child(3), a:nth-child(4) {
            display: none;
        }
    }
`

const Footer = ({data, children}) => (
    <FooterGroup>
        <Helmet>
            <script src="https://kit.fontawesome.com/077761ae24.js" crossOrigin="anonymous"></script>
        </Helmet>
        <Copyright>{children}</Copyright>
        <LinkGroup>
            {footerlinks.cells.map(cell => (
                <a key={cell.url} href={cell.url} target="_blank" rel="noreferrer">{cell.title}</a>
            ))}
        </LinkGroup>
    </FooterGroup>
)

export default Footer