import * as React from "react"

const Brkfst = () => (
    <svg width="100%" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.028 22.34C17.38 22.34 21.29 20.062 21.29 13.942C21.29 7.85601 17.38 5.54401 13.3 5.54401C10.988 5.54401 9.25401 6.12201 7.99601 7.34601V0.98801H0.346008V22H5.00401C5.71801 21.762 6.53401 20.878 6.94201 19.314C8.03001 21.388 10.138 22.34 13.028 22.34ZM10.546 19.62C8.84601 19.62 7.99601 18.26 7.99601 13.942C7.99601 9.65801 8.84601 8.29801 10.546 8.29801C12.212 8.29801 13.096 9.65801 13.096 13.942C13.096 18.26 12.212 19.62 10.546 19.62Z" fill="url(#paint0_linear)"/>
        <path d="M32.2675 22V12.888C32.2675 10.644 33.2875 9.86201 34.3075 9.86201C35.6335 9.86201 36.2455 10.814 36.2455 12.786C36.2455 13.364 36.2115 14.146 36.0415 14.962H43.1815C43.7255 13.908 43.9635 12.684 43.9635 11.596C43.9635 8.06001 41.3795 5.54401 37.5715 5.54401C34.8515 5.54401 33.0835 6.70001 31.9955 9.01201C31.6895 7.21001 30.7715 6.19001 30.0235 5.88401H24.6175V22H32.2675Z" fill="url(#paint1_linear)"/>
        <path d="M55.0448 22V14.656H55.8608C56.9488 14.656 57.7988 14.86 58.3088 16.39L60.1448 22H68.3728L66.2648 17.036C65.2788 14.724 64.1908 13.296 61.9128 12.65C63.6128 12.072 64.7008 11.188 65.9248 9.42001L68.3728 5.88401H63.7488L61.5728 9.21601C60.2128 11.392 58.8868 12.038 56.7788 12.038H55.0448V0.98801H47.3948V22H55.0448Z" fill="url(#paint2_linear)"/>
        <path d="M71.8711 22H79.5551V7.99201H82.0371V5.88401H79.5551V4.86401C79.5551 3.67401 80.0651 3.13001 80.9151 3.13001C81.3231 3.13001 81.7311 3.23201 82.1731 3.36801L82.8191 1.36201C81.8331 0.954011 80.5411 0.64801 79.0111 0.64801C74.5911 0.64801 72.0411 2.41601 71.8711 5.88401H70.0691V7.99201H71.8711V22Z" fill="url(#paint3_linear)"/>
        <path d="M92.6759 22.34C99.4759 22.34 103.08 20.3 103.08 16.594C103.08 10.712 91.5879 12.31 91.5879 9.18201C91.5879 8.19601 92.2679 7.78801 93.2199 7.78801C94.2399 7.78801 95.0219 8.50201 95.0219 9.72601V10.202H102.638C102.672 10.066 102.672 9.93001 102.672 9.82801C102.672 7.07401 99.1359 5.54401 92.7779 5.54401C87.0999 5.54401 83.0879 7.38001 83.0879 10.644C83.0879 16.424 95.1579 14.69 95.1579 18.192C95.1579 19.144 94.3419 19.858 92.8459 19.858C91.3159 19.858 90.5679 19.212 90.5679 17.852C90.5679 17.58 90.6019 17.274 90.6359 16.934H83.0199V17.172C83.0199 20.334 85.9439 22.34 92.6759 22.34Z" fill="url(#paint4_linear)"/>
        <path d="M113.234 22.34C116.498 22.34 118.266 21.048 119.422 19.008L117.858 17.784C117.348 18.6 116.804 19.042 115.954 19.042C115.002 19.042 114.424 18.532 114.424 17.444V8.46801H118.742V5.88401H114.424V1.90601L106.774 2.65401V5.88401H104.938V8.46801H106.774V17.716C106.774 20.98 109.222 22.34 113.234 22.34Z" fill="url(#paint5_linear)"/>
        <path d="M121.32 15.472H132.778V11.766H121.32V15.472Z" fill="url(#paint6_linear)"/>
        <defs>
            <linearGradient id="paint0_linear" x1="66.5619" y1="0.64801" x2="66.5619" y2="22.34" gradientUnits="userSpaceOnUse">
                <stop offset="0.427083" stopColor="#E7793B"/>
                <stop offset="0.604167" stopColor="#37B4BC"/>
            </linearGradient>
            <linearGradient id="paint1_linear" x1="66.5619" y1="0.64801" x2="66.5619" y2="22.34" gradientUnits="userSpaceOnUse">
                <stop offset="0.427083" stopColor="#E7793B"/>
                <stop offset="0.604167" stopColor="#37B4BC"/>
            </linearGradient>
            <linearGradient id="paint2_linear" x1="66.5619" y1="0.64801" x2="66.5619" y2="22.34" gradientUnits="userSpaceOnUse">
                <stop offset="0.427083" stopColor="#E7793B"/>
                <stop offset="0.604167" stopColor="#37B4BC"/>
            </linearGradient>
            <linearGradient id="paint3_linear" x1="66.5619" y1="0.64801" x2="66.5619" y2="22.34" gradientUnits="userSpaceOnUse">
                <stop offset="0.427083" stopColor="#E7793B"/>
                <stop offset="0.604167" stopColor="#37B4BC"/>
            </linearGradient>
            <linearGradient id="paint4_linear" x1="66.5619" y1="0.64801" x2="66.5619" y2="22.34" gradientUnits="userSpaceOnUse">
                <stop offset="0.427083" stopColor="#E7793B"/>
                <stop offset="0.604167" stopColor="#37B4BC"/>
            </linearGradient>
            <linearGradient id="paint5_linear" x1="66.5619" y1="0.64801" x2="66.5619" y2="22.34" gradientUnits="userSpaceOnUse">
                <stop offset="0.427083" stopColor="#E7793B"/>
                <stop offset="0.604167" stopColor="#37B4BC"/>
            </linearGradient>
            <linearGradient id="paint6_linear" x1="66.5619" y1="0.64801" x2="66.5619" y2="22.34" gradientUnits="userSpaceOnUse">
                <stop offset="0.427083" stopColor="#E7793B"/>
                <stop offset="0.604167" stopColor="#37B4BC"/>
            </linearGradient>
        </defs>
    </svg>
)

export default Brkfst