import * as React from "react"

import Header from "../components/Header"
import Footer from "../components/Footer"
import "./Layout.css"

const Layout = ({ pageTitle, children }) => {
  return (
    <main>
        <Header />
        {children}
        <Footer>
            © {new Date().getFullYear()} Dan Hanasono
        </Footer>
    </main>
  )
}

export default Layout